<template>
  <div class="purchase-detail-recode-list">
    <div class="handle-list" v-if="handleList && handleList.length > 0">
      <div style="line-height: 30px;" v-for="item in handleList" :key="item.id">
        {{getMsg(item)}}
      </div>
    </div>
    <div class="no-data" v-else>暂无签收记录</div>
  </div>
</template>

<script>
export default {
  props: {
    handleList: { type: Array, defualt: () => [] }
  },
  methods: {
    getMsg (item) {
      let msg = ''
      if (item.status === 'over') {
        msg += item.orgName + ' 已签收，' + item.handleUserName + '在 ' + item.handleTime + ' 进行了签收'
      } else {
        msg += item.orgName + ' ' + item.userName + ' 未签收'
      }
      return msg
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-detail-recode-list {
  color: #515a6e;
  td {
    height: 30px;
  }
  .no-data {
    padding-bottom: 10px;
  }
}
</style>