<template>
  <div class="detial-item" v-if="data">
    <div class="base-info">
      <fm-title title-text="发文申请"></fm-title>
      <base-info size="auto" :data="data" :showSendCode="true"></base-info>
    </div>
    <div class="deal-recode show-detail-record">
      <fm-title title-text="发文文件"></fm-title>
      <parm-file :data="data" :fun="{get: true}"></parm-file>
    </div>
    <div class="deal-recode show-detail-record" >
      <fm-title title-text="审批记录"></fm-title>
      <record :recordList="data.statusActionRecord || []"></record>
    </div>
    <div class="deal-recode show-detail-record">
      <fm-title title-text="下发文件"></fm-title>
      <over-file :data="data"></over-file>
    </div>
    <div class="deal-recode show-detail-record" >
      <fm-title title-text="签收情况"></fm-title>
      <SigninRecord :handle-list="data.handleList || []"></SigninRecord>
    </div>
  </div>
</template>

<script>
import baseInfo from './baseInfo'
import record from './record'
import parmFile from './parmFile'
import overFile from './overFile'
import SigninRecord from './signinRecord'

import {
  officialDocumentSendRequest
} from '../../../api'

export default {
  components: {
    baseInfo,
    record,
    parmFile,
    overFile,
    SigninRecord
  },
  data () {
    return {
      show: false,
      data: {}
    }
  },
  watch: {
    id: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  props: {
    id: { type: Number },
    statusConfig: { type: Object, defualt: () => {} },
    statusMap: { type: Object, defualt: () => {} }
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return
      }
      let datas = await officialDocumentSendRequest.get({
        statusActionRecord: 1,
        id: this.id
      })
      datas.forEach(v => {
        v.fileIds = (v.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        v.overFileIds = (v.overFileIds || '').split(',').filter(v => v).map(v => Number(v))
      })
      this.data = datas[0]
    }
  }
}
</script>

<style lang="less" scoped>
.detial-item {
  position: relative;
  border-radius: 3px;
  .right-btns {
    position: absolute;
    top: 0;
    right: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .recode-switch {
    display: flex;
    justify-content: center;
  }
  .icon-jiantouyou {
    font-size: 20px;
    color: #F4628F;
    line-height: 1.2;
  }
  .back-i {
    transform:rotate(-90deg) !important;
  }
  .deal-recode {
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
    overflow-y:auto;
    max-height: 200px;
  }
  .step-list {
    overflow-x: auto;
  }
}
</style>