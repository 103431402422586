<template>
  <div>
    <div class="value" :style="{'max-width': width + 'px'}" @mouseover="open = true" @mouseout="open = false">{{value}}</div>
    <fm-poper :value="open" :position="position">
      <div :style="{'max-width': width + 'px'}" class="long-value">
        {{value}}
      </div>
    </fm-poper>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },
  methods: {
    copen () {
      this.open = !this.open
    }
  },
  props: {
    value: {
      type: String
    },
    width: {
      type: Number,
      default: 200
    },
    position: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>

<style lang="less" scoped>
.long-value {
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px 15px;
}
.value {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>