<template>
  <div class="org-user-choose">
    <fm-input-new size="small" v-model="searchText" placeholder="请输入机构名称搜索"></fm-input-new>
    <Tree :data="showNods" ref="orgUserChoose" show-checkbox></Tree>
  </div>
</template>

<script>
export default {
  props: {
    hisData: {
      type: Array,
      default: () => {
        return []
      }
    },
    orgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    userList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    showNods () {
      // return this.nodes.filter(v => !this.searchText || v.title.includes(this.searchText) || (v.children && v.children.find(v1 => v1.title.includes(this.searchText))))
      return this.nodes.filter(v => !this.searchText || v.title.includes(this.searchText))
    },
    nodes () {
      let datas = this.orgList
      let fn = (org) => {
        let userList = this.userList.filter(v => v.orgRoles && v.orgRoles.find(v1 => v1.orgId === org.data.id))
        return {
          title: org.data.name,
          data: org.data,
          type: 'org',
          children: userList.map(v => {
            return {
              type: 'user',
              title: v.name,
              checked: Boolean(this.hisData.find(v1 => v1.userId === v.id && v1.orgId === org.data.id)),
              disabled: (this.hisData.find(v1 => v1.userId === v.id && v1.orgId === org.data.id) || {disabled: false}).disabled,
              data: {
                id: v.id,
                name: v.name,
                orgId: org.data.id
              }
            }
          })
        }
      }
      let result = datas.map(fn)
      return result
    }
  },
  methods: {
    getChooseData () {
      let datas = []
      this.nodes.forEach(v => {
        let checkedData = v.children ? v.children.filter(v1 => v1.checked) : []
        if (checkedData.length > 0) {
          datas = datas.concat(checkedData)
        }
      })
      return datas
    },
    checked (data) {
      console.log(data)
      // let checked = data.checked
      // let type = data.data.type
      // if (type === 'user') {
      //   let user = data.data.data
      //   console.log(user, checked, type)
      //   if (checked) {
      //     let newChooseData = this.chooseData.filter(v => !(v.orgId === user.orgId && v.userId === user.id))
      //     newChooseData.push({
      //       userId: user.id,
      //       orgId: user.orgId
      //     })
      //     this.chooseData = newChooseData
      //   } else {
      //     this.chooseData = this.chooseData.filter(v => !(v.orgId === user.orgId && v.userId === user.id))
      //   }
      // }
      // console.log(this.chooseData)
    }
  },
  data () {
    return {
      chooseData: [],
      searchText: ''
    }
  }
}
</script>

<style lang="less" scoped>
.org-user-choose {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}
</style>

<style lang="less">
.org-user-choose {
  .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #F4628F;
    background-color: #F4628F;
  }
  .ivu-checkbox-indeterminate .ivu-checkbox-inner {
    border-color: #F4628F;
    background-color: #F4628F;
  }
}
</style>

