<template>
  <div class="purchase-method-detail-file-info">
    <file-manage
      :file-data-ids="data.overFileIds"
      :funs="{get: true}"></file-manage>
  </div>
</template>

<script>
import FileManage from '@/components/base/FileManage'

export default {
  components: {
    FileManage
  },
  props: {
    data: { type: Object, defualt: () => null }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-file-info {
  
}
</style>