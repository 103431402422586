<template>
  <sign-page
    title-text="发文管理"
    :request="request"
    :column-list="columnList"
    @dataFileUpdate="dataFileUpdate"
    table-size="small"
    ref="xfPage"
    :getFileDataIds="getFileDataIds"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :title-menus="[{key: 'out_put', label: '导出'}]"
    :table-actions-width="250"
    @clickTitleMenu="clickTitleMenu"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="发文申请明细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.id"></detail>
    </fm-modal>
    <fm-modal title="发文下发" v-model="modalXf" :mask-closable="false" width="600px" v-if="modalXf">
      <org-user-choose
        :user-list="userList"
        :org-list="$store.getters.orgList"
        :his-data="chooseData.handleList"
        style="max-height: 50vh;"
        ref="ouc"
      />
      <div class="btns">
        <fm-btn style="margin-right: 20px;" @click="signin">下发</fm-btn>
        <fm-btn @click="modalXf = false">取消</fm-btn>
      </div>
    </fm-modal>
    <template v-slot:title>
      <div style="display: flex;align-items: center;margin-left: 20px;">
        <fm-date-picker v-model="year" @change="yearChange" :clearable="true" type="year" placement="bottom-end" placeholder="请选择年份" style="width: 200px" />
      </div>
    </template>
  </sign-page>
</template>

<script>
import XLSX from 'xlsx'
// import { downloadExcelWithStyle } from '@/components/excel/lib'
import {
  officialDocumentSendRequest as request,
  statusManageRequest
} from '../../api'

import {
  userRequest
} from '@/api'

// import {
//   fileHepler
// } from '@/fmlib'

import TextCell from '@/components/base/TextCell'
import OrgUserChoose from './apply/orgUserChoose'
import sysEnv from '@/env/env'

import detail from './apply/detail'

export default {
  components: {
    detail,
    OrgUserChoose
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'editSendCode',
        label: '文号编辑'
      },
      {
        key: 'data_file',
        label: '下发文件',
        show (data) {
          return data.status === 'end'
        }
      },
      {
        key: 'signin',
        label: '下发',
        show (data) {
          return data.signinStatus === 'wait'
        }
      },
      {
        key: 'back',
        label: '撤销',
        show (data) {
          return data.actions.find(v => v.key === 'back_status_switch') && true
        }
      }]
    },
    outPutColumnList () {
      return [{
        title: '编码',
        field: 'code',
        sort: true
      },
      {
        title: '文件名称',
        field: 'title',
      },
      {
        title: '收/发',
        field: 'sf',
      },
      {
        title: '发文单位',
        field: 'orgName',
      },
      {
        title: '文号',
        field: 'sendCode',
      },
      {
        title: '发文时间',
        field: 'sendTimes',
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编码',
          field: 'code',
          sort: true
        },
        {
          title: '类型',
          field: 'type',
          sort: true
        },
        {
          title: '文件名称',
          field: 'title',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.title,
                width: 540
              }
            })
          }
        },
        {
          title: '备注',
          field: 'remark',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.remark,
                width: 270
              }
            })
          }
        },
        {
          title: '发文号',
          field: 'sendCode',
          sort: true
        },
        {
          title: '发文机构',
          field: 'orgName',
          sort: true
        },
        {
          title: '发文份数',
          field: 'num',
          sort: true
        },
        {
          title: '发文日期',
          field: 'sendTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.sendTime ? rowData.sendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '归入案卷号',
          field: 'getCode',
          sort: true
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    yearChange () {
      this.$refs.xfPage.loadData()
    },
    clickTitleMenu () {
      let data = this.$refs.xfPage.dataList.map(v => {
        return Object.assign({}, v, {
          sf: '发',
          sendTimes: v.sendTime ? v.sendTime.slice(0, 10) : ''
        })
      })
      const workbook = XLSX.utils.book_new()
      Array.from(new Set(data.map(v => v.type))).forEach(type => {
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet([
          this.outPutColumnList.map(v => v.title),
          ...data.filter(v => v.type === type).map(item => {
            return this.outPutColumnList.map(v => item[v.field])
          })
        ]), type || '其他')
      })
      XLSX.writeFile(workbook, (sysEnv.title || '') + (this.year ? this.year.getFullYear() + '年' : '') + '公文发文登记表.xlsx')
    },
    async dataFileUpdate (parm, pageVm) {
      await request.overFiles(pageVm.chooseData.id, {
        overFileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    getFileDataIds (data) {
      return data.overFileIds ? data.overFileIds.split(',') : []
    },
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      } else if (parm.action === 'signin') {
        // this.signinOrgIds = this.chooseData.signinOrgIds.map(v => Number(v))
        this.modalXf = true
      } else if (parm.action === 'editSendCode') {
        const result = await this.$dialog.confirm({title: '请输入发文号', promptValue: this.chooseData.sendCode, showInput: true})
        if (result !== false) {
          await request.updateSendCode(this.chooseData.id, {
            sendCode: result
          })
          vm.loadData()
        }
      } else {
        let action = this.chooseData.actions.find(v => v.key === 'back_status_switch')
        if (action) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该下发申请吗?'})
          if (result) {
            await request.switchStatus(this.chooseData.id, {
              statusActionKey: action.key,
              statusActionRecordId: action.statusActionRecordId,
            })
            vm.loadData()
          }
        }
      }
    },
    async loadUser () {
      this.userList = await userRequest.get({needRoleInfo: 1})
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'officialDocumentSend'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.config = {}
      this.config['null'] = c.statusSwitchList.filter(v => v.isHost && !v.tag)
    },
    async signin () {
      let datas = this.$refs.ouc.getChooseData()
      datas = datas.filter(v => v.type === 'user').map(v => v.data)
      await request.assign(this.chooseData.id, {
        orgUserList: datas.map(v => {
          return {
            orgId: v.orgId,
            userId: v.id
          }
        })
      })
      this.$notify({
        title: '系统提示',
        message: '完成下发。',
        type: 'success'
      })
      this.modalXf = false
      this.$refs.xfPage.loadData()
    },
    async loadData (parm) {
      if (this.year) {
        parm.sendYear = this.year.getFullYear()
      } else {
        parm.sendYear = undefined
      }
      let data = await request.get(parm)
      let signinStatusMap = {
        'apply': '审批中',
        'wait': '待下发',
        'doing': '签收中',
        'over': '已完成'
      }
      data.forEach(v => {
        v.signinStatusText = signinStatusMap[v.signinStatus]
      })
      return data
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
    this.loadUser()
    this.loadConfig()
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      userList: [],
      signinOrgIds: [],
      statusMap: {},
      modalXf: false,
      year: new Date(),
      statusConfig: {},
      modal: false,
      chooseData: null
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}
</style>